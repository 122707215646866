import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getAPIData } from '../utils/apiFunction';
import { Typography } from '@mui/material';
import TextField from "@mui/material/TextField";
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import MenuItem from "@mui/material/MenuItem";


const DriverSelect = (props) => {
    const [drivers, setDrivers] = React.useState([]);
    const [driver, setDriver] = React.useState(props.driver);
    const [cache, setCache] = React.useState(null);
    const setDriv = (val) => {
        setDriver(val);
        props.setDriver(val);
    }
    const lookupDriv = (staff) => {
        var stff = drivers.find((dr) => { return (dr.id === staff.id) });
        setDriv(stff);
    }
    React.useEffect(() => {

        if (props.staffId) {
            var stff = drivers.find((dr) => { return (dr.id === props.staffId) });
            setDriv(stff);
        } else {
            if (props.useCache) {
                GetAllCacheData(lookupDriv, 'staffMember')
            }
        }
    }, [drivers, props.staffId]);
    const sortNames = (driv) => {
        var sortDriv = driv.sort((a, b) => {
            return a.firstname > b.firstname ? 1 : -1;
        })
        setDrivers(sortDriv);
    }
    React.useEffect(() => {
        getAPIData('/StaffMember/Get/Current', sortNames)
    }, [props.reload]);
    const changeDriver = (event) => {
        setDriv(event.target.value);
        if (props.useCache) {
            addToCache(event.target.value, 'staffMember');
        }
    }
    return (
        <React.Fragment>
            {props.noTitle ? '' : <h3 style={{ fontWeight: 450 }}>Staff Member</h3>}

            <TextField
                id="outlined-select-currency"
                select
                label={props.noTitle ? 'Staff Member' : ''}
                disabled={props.disabled}
                value={driver}
                onChange={changeDriver}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                {drivers.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        {option.firstname + " " + option.surname}
                    </MenuItem>
                ))}
            </TextField>
        </React.Fragment >
    )
}

export default DriverSelect;
